import React, { useState, useContext, useRef, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import SEO from "../components/Seo";
import { useIntl, navigate } from "gatsby-plugin-intl";
import tw from "twin.macro";
import { FaApple, FaGoogle, FaFacebook } from "react-icons/fa";
import Img from "react-cloudinary-lazy-image";
import { FirebaseAuthContext } from "../context/FirebaseAuthContext";
import { BlockUIContext } from "../context/BlockUIContext";

const images = [
    "bg1_yvyryl",
    "bg2_ijitca",
    "bg3_gxnbst",
    "bg4_uh5dwu",
    "bg5_mg9dl5",
    "bg6_fvsahc",
    "bg7_d8wdz8",
    "bg9_rlhwix",
    "bg10_rnmdtc",
    "bg11_ycu4rj",
    "bg12_jsistc",
    "bg13_xezo6w",
    "bg14_vjbgw3",
    "bg15_hhhkiy",
];

const random = Math.floor(Math.random() * images.length);

const Login = () => {
    const intl = useIntl();
    const { isAuthenticated, loading } = useContext(FirebaseAuthContext);
    const { setBlocking } = useContext(BlockUIContext);

    // Datos del formulario
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const inputEmail = useRef();
    const inputPassword = useRef();
    const [emailMessage, setEmailMessage] = useState(null);
    const [passwordMessage, setPasswordMessage] = useState(null);
    const [genericMessage, setGenericMessage] = useState(null);

    const handleAuthErrors = (error) => {
        setBlocking(false);
        setEmailMessage(null);
        setPasswordMessage(null);

        if (error) {
            switch (error.code) {
                case "auth/invalid-email":
                    inputEmail.current.focus();
                    inputEmail.current.select();
                    setEmailMessage(intl.formatMessage({ id: "13" }));
                    break;

                case "auth/wrong-password":
                    inputPassword.current.focus();
                    setPasswordMessage(intl.formatMessage({ id: "14" }));
                    break;

                case "auth/user-not-found":
                    inputEmail.current.focus();
                    setEmailMessage(intl.formatMessage({ id: "15" }));
                    break;

                case "auth/popup-closed-by-user":
                    inputEmail.current && inputEmail.current.focus();
                    setGenericMessage({
                        message: intl.formatMessage({ id: "16" }),
                        success: false,
                    });
                    break;

                case "auth/operation-not-allowed":
                    inputEmail.current.focus();
                    setGenericMessage({
                        message: intl.formatMessage({ id: "17" }),
                        success: false,
                    });
                    break;

                case "auth/too-many-requests":
                    inputEmail.current.focus();
                    setGenericMessage({
                        message: intl.formatMessage({ id: "66" }),
                        success: false,
                    });
                    break;

                default:
                    break;
            }
        }
    };
    const handleAppleAuth = () => {};
    const handleFacebookAuth = () => {};
    const handleGoogleAuth = async (event) => {
        try {
            event && event.preventDefault();
            setBlocking(true);
            const provider = new firebase.auth.GoogleAuthProvider();

            firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    return firebase
                        .auth()
                        .signInWithPopup(provider)
                        .then(() => {
                            // console.log(user);
                            setBlocking(false);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    handleAuthErrors(err);
                });
        } catch (err) {
            console.log(err);
            handleAuthErrors(err);
        }
    };
    const handleLoginSubmit = async (event) => {
        try {
            event && event.preventDefault();
            setBlocking(true);
            firebase
                .auth()
                .setPersistence(
                    rememberMe
                        ? firebase.auth.Auth.Persistence.LOCAL
                        : firebase.auth.Auth.Persistence.SESSION,
                )
                .then(() => {
                    return firebase.auth().signInWithEmailAndPassword(email, password);
                })
                .then(() => {
                    // console.log(user);
                    setBlocking(false);
                })
                .catch((err) => {
                    console.log(err);
                    handleAuthErrors(err);
                });
        } catch (err) {
            console.log(err);
            handleAuthErrors(err);
        }
    };

    useEffect(() => {
        inputEmail.current && inputEmail.current.focus();
    }, []);

    // Mostramos ventanda de loading
    useEffect(() => {
        setBlocking(loading);
    }, [loading, setBlocking]);

    // En caso de canviar a autenticado vamos a la ventana inicial
    useEffect(() => {
        isAuthenticated && navigate("/");
    }, [isAuthenticated]);

    return (
        <>
            <SEO title={intl.formatMessage({ id: "3" })} />

            <div css={[tw`min-h-screen bg-gray-50 flex`]}>
                {/** Formulario */}
                <div
                    css={[
                        tw`flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24`,
                    ]}
                >
                    {/** Logotipo */}
                    <div css={[tw`mx-auto w-full max-w-sm lg:w-96`]}>
                        <div>
                            <div css={[tw`duration-300 w-48`]}>
                                <Img
                                    css={[tw`duration-300`]}
                                    cloudName="moybu"
                                    imageName={`bdtmm/dashboard/logos/logo-text_tv5odt`}
                                    fluid={{
                                        maxWidth: 512,
                                    }}
                                    //urlParams={'g_face,c_lfill'}
                                    alt={"logo bdtmm"}
                                />
                            </div>

                            <h2
                                css={[
                                    tw`mt-6 text-3xl font-extrabold text-gray-600 font-montserrat`,
                                ]}
                            >
                                {intl.formatMessage({ id: "4" })}
                            </h2>
                        </div>

                        <div css={[tw`mt-8`]}>
                            <div>
                                <div>
                                    <p css={[tw`text-sm font-medium text-gray-700`]}>
                                        {intl.formatMessage({ id: "5" })}
                                    </p>
                                    <div css={[tw`mt-1 grid grid-cols-3 gap-3`]}>
                                        <div>
                                            <button
                                                disabled={true}
                                                onClick={(e) => {
                                                    handleFacebookAuth(e);
                                                }}
                                                css={[
                                                    tw`cursor-not-allowed opacity-25 w-full inline-flex justify-center py-2 px-4 border border-blue-700 rounded-md shadow-sm bg-white text-sm font-medium text-blue-700 hover:bg-blue-700 hover:text-white duration-300 hover:border-blue-700`,
                                                ]}
                                            >
                                                <span css={[tw`sr-only`]}>
                                                    {intl.formatMessage({ id: "6" })}
                                                </span>
                                                <FaFacebook css={[tw`w-5 h-5`]} />
                                            </button>
                                        </div>

                                        <div>
                                            <button
                                                disabled={true}
                                                onClick={(e) => {
                                                    handleAppleAuth(e);
                                                }}
                                                css={[
                                                    tw`cursor-not-allowed opacity-25 w-full inline-flex justify-center py-2 px-4 border border-black rounded-md shadow-sm bg-white text-sm font-medium text-black hover:bg-black hover:text-white duration-300 hover:border-black`,
                                                ]}
                                            >
                                                <span css={[tw`sr-only`]}>
                                                    {intl.formatMessage({ id: "7" })}
                                                </span>
                                                <FaApple css={[tw`w-5 h-5`]} />
                                            </button>
                                        </div>

                                        <div>
                                            <button
                                                onClick={(e) => {
                                                    handleGoogleAuth(e);
                                                }}
                                                css={[
                                                    tw`w-full inline-flex justify-center py-2 px-4 border border-red-700 rounded-md shadow-sm bg-white text-sm font-medium text-red-700 hover:bg-red-700 hover:text-white hover:border-red-700 duration-300`,
                                                ]}
                                            >
                                                <span css={[tw`sr-only`]}>
                                                    {intl.formatMessage({ id: "7" })}
                                                </span>
                                                <FaGoogle css={[tw`w-5 h-5`]} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div css={[tw`mt-6 relative`]}>
                                    <div
                                        css={[tw`absolute inset-0 flex items-center`]}
                                        aria-hidden="true"
                                    >
                                        <div css={[tw`w-full border-t border-gray-300`]}></div>
                                    </div>
                                    <div css={[tw`relative flex justify-center text-sm`]}>
                                        <span css={[tw`px-2 bg-gray-50 text-gray-500`]}>
                                            {intl.formatMessage({ id: "9" })}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div css={[tw`mt-6`]}>
                                <form onSubmit={handleLoginSubmit} css={[tw`space-y-6`]}>
                                    <div>
                                        <label
                                            htmlFor="email"
                                            css={[tw`block text-sm font-medium text-gray-700`]}
                                        >
                                            {intl.formatMessage({ id: "10" })}
                                        </label>
                                        <div css={[tw`mt-1`]}>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                value={email}
                                                ref={inputEmail}
                                                onChange={(event) =>
                                                    setEmail(event.currentTarget.value)
                                                }
                                                css={[
                                                    tw`duration-300 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`,
                                                ]}
                                            />
                                            {emailMessage && (
                                                <div
                                                    className={`text-red-500 text-xs font-montserrat mt-2 ml-3 ${
                                                        emailMessage ? "block" : "hidden"
                                                    }`}
                                                >
                                                    {emailMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div css={[tw`space-y-1`]}>
                                        <label
                                            htmlFor="password"
                                            css={[tw`block text-sm font-medium text-gray-700`]}
                                        >
                                            {intl.formatMessage({ id: "11" })}
                                        </label>
                                        <div css={[tw`mt-1`]}>
                                            <input
                                                value={password}
                                                ref={inputPassword}
                                                onChange={(event) =>
                                                    setPassword(event.currentTarget.value)
                                                }
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                css={[
                                                    tw`duration-300 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`,
                                                ]}
                                            />
                                            {passwordMessage && (
                                                <div
                                                    css={[
                                                        tw`text-red-500 text-xs font-montserrat mt-2 ml-3`,
                                                    ]}
                                                >
                                                    {passwordMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div css={[tw`flex items-center justify-between`]}>
                                        <div css={[tw`flex items-center`]}>
                                            <input
                                                id="remember_me"
                                                name="remember_me"
                                                type="checkbox"
                                                checked={rememberMe}
                                                value={rememberMe}
                                                onChange={(e) => {
                                                    setRememberMe(!rememberMe);
                                                }}
                                                css={[
                                                    tw`h-4 w-4 text-indigo-700 focus:ring-indigo-500 border-gray-300 rounded duration-300`,
                                                ]}
                                            />
                                            <label
                                                htmlFor="remember_me"
                                                css={[tw`ml-2 block text-sm text-gray-900`]}
                                            >
                                                {intl.formatMessage({ id: "12" })}
                                            </label>
                                        </div>

                                        {/*
                                                <div css={[tw`text-sm`]}>
                                                    <Link
                                                        to="/"
                                                        css={[tw`font-medium text-indigo-700 hover:text-indigo-500`]}
                                                    >
                                                        ¿Has perdido la clave?
                                                    </Link>
                                                </div>
                                                */}
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            css={[
                                                tw`font-montserrat bg-primary hover:bg-opacity-80 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`,
                                            ]}
                                        >
                                            {intl.formatMessage({ id: "3" })}
                                        </button>

                                        {/** Mensaje genérico */}
                                        {genericMessage && (
                                            <div
                                                css={[
                                                    tw`text-xs font-montserrat mt-5 ml-3 text-center`,
                                                    genericMessage.success
                                                        ? tw`text-green-500`
                                                        : tw`text-red-500`,
                                                ]}
                                            >
                                                {genericMessage.message}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/** Copyright */}
                    <div css={[tw`mx-auto w-full max-w-sm lg:w-96 bottom-5 mt-10`]}>
                        <div css={[tw`mt-1 text-gray-600 text-center text-xs font-montserrat`]}>
                            Copyright &copy; {new Date().getFullYear()} - Ajuntament de Mont-roig
                            del Camp
                        </div>
                    </div>
                </div>

                {/** Fondo lateral */}
                <LateralBackground image={images[random]} />
            </div>
        </>
    );
};

const LateralBackground = ({ image }) => {
    return (
        <>
            <div css={[tw`hidden lg:block relative w-0 flex-1`]}>
                <div
                    css={[
                        tw`absolute inset-0 h-full w-full object-cover bg-gray-100 overflow-hidden`,
                    ]}
                >
                    <Img
                        css={[tw`absolute inset-0 h-full w-full object-cover`]}
                        cloudName="moybu"
                        imageName={`bdtmm/dashboard/bgs/${image}`}
                        fluid={{
                            maxWidth: 1024,
                        }}
                        urlParams={"g_face,c_lfill,e_art:fes,e_blur:50,b_black,o_85"}
                        alt={""}
                    />
                </div>
            </div>
        </>
    );
};

export default Login;
